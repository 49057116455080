import React, { Suspense, lazy, useState } from "react";
import './App.css';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import ProtectedRoute from "./Route/ProtectedRoute";
import PublicRoute from "./Route/PublicRoute";
import PasswordRoute from "./Route/PasswordRoute"
import { AuthProvider } from "./Route/AuthContext";
import Header from "./Layout/Header/Header";
import Footer from "./Layout/Footer/Footer";
import { keyframes } from '@emotion/react';
import Sidebar from "./Layout/Sidebar.js/Sidebar";
import { Box, Typography } from "@mui/material";
import { BalanceProvider } from './Component/Balance/BalanceProvider';
import wait from "./Assets/wait.gif"

const Home = lazy(() => import("./Component/Home/Home"));
const Farm = lazy(() => import("./Component/Fram/Farm"));
const Chicken = lazy(() => import("./Component/Chicken/Chicken"));
const Store = lazy(() => import("./Component/Store/Store"));
const Login = lazy(() => import("./Auth/Login"));
const RejisterChoose = lazy(() => import("./Auth/RegisterChooes"));
const Signin = lazy(() => import("./Auth/Signin"));
const Profile = lazy(() => import("./Component/Profile/Profile"));
const MyHens = lazy(() => import("./Component/My_Hens/MyHens"));
const StoreDetails = lazy(() => import("./Component/Store/StoreDetails"));
const EggDetails = lazy(() => import("./Component/Egg/EggDetails"));
const Cart = lazy(() => import("./Component/Cart/Cart"));
const FarmDetails = lazy(() => import("./Component/Fram/FarmDetails"));
const ChickenDetails = lazy(() => import("./Component/Chicken/ChickenDetails"));
const GoogleMap = lazy(() => import("./Component/GoogleMap/GoogleMap"));
const GoogleMapAll = lazy(() => import("./Component/GoogleMap/GoogleMapAll"));
const RequestFarm = lazy(() => import("./Component/become_farm/RequestFarm"));
const FirstRequest = lazy(() => import("./Component/become_farm/FirstRequest"));
const Backyard = lazy(() => import("./Component/become_farm/Backyard"));
const StepOne = lazy(() => import("./Component/become_farm/StepOneFarm"));
const StepTwo = lazy(() => import("./Component/become_farm/StepTwoFarm"));
const StepThree = lazy(() => import("./Component/become_farm/StepThreeFarm"));
const StepOneMap = lazy(() => import("./Component/become_farm/StepOneMap"));
const HensDetails = lazy(() => import("./Component/My_Hens/HensDetails"));
const EditHens = lazy(() => import("./Component/My_Hens/EditHens"));
const Address = lazy(() => import("./Component/Address/Address"));
const AddAddress = lazy(() => import("./Component/Address/AddAddress"));
const EditAddress = lazy(() => import("./Component/Address/EditAddress"));
const EggsOrderDetails = lazy(() => import("./Component/Cart/EggsOrderDetails"));
const EggsOrderAddress = lazy(() => import("./Component/Cart/EggsOrderAddress"));
const EgssOrderTime = lazy(() => import("./Component/Cart/EggsOrderTime"));
const About = lazy(() => import("./Component/Home/About"));
const Faqs = lazy(() => import("./Component/Home/FAQs"));
const Privacy = lazy(() => import("./Component/Home/Privacy"));
const Terms = lazy(() => import("./Component/Home/Terms"));
const Contact = lazy(() => import("./Component/Home/Contact"));
const Balance = lazy(() => import("./Component/Balance/Balance"));
const AddBalance = lazy(() => import("./Component/Balance/AddBalance"));
const Stripe = lazy(() => import("./Component/Balance/Stripo"));
const FirstTrack = lazy(() => import("./Component/My_Hens/FirstTrack"));
const SecondTrack = lazy(() => import("./Component/My_Hens/SecondTrack"));
const Orders = lazy(() => import("./Component/Orders/Orders"));
const OrderHistoryDetails = lazy(() => import("./Component/Orders/OrdersHistoryDetails"));
const OrderIncomingDetails = lazy(() => import("./Component/Orders/OrderIncomingDetails"));
const ChickenOrderDetails = lazy(() => import("./Component/Cart/ChickenOrderDetails"));
const RequestAddress = lazy(() => import("./Component/become_farm/RequsetAddress"));
const RequestTime = lazy(() => import("./Component/become_farm/RequsetTime"));
const RequestOrder = lazy(() => import("./Component/become_farm/RequestOrder"));
const DelieverdChickenAddress = lazy(() => import("./Component/My_Hens/DelieverdChickkenAddress"));
const DelieverdChickenTime = lazy(() => import("./Component/My_Hens/DelieverdChickenTime"));
const DelieverdChickenDetails = lazy(() => import("./Component/My_Hens/DelieverdChickenDetails"));
const Mywishlist = lazy(() => import("./Component/My_Wishlist/Mywishlist"));
const MyEggs = lazy(() => import("./Component/Egg/Egg"));
const EditStore = lazy(() => import("./Component/Egg/EditStore"));
const DeliverEggAddress = lazy(() => import("./Component/Egg/DeliverEggsAddress"));
const DeliverEggsTime = lazy(() => import("./Component/Egg/DeliverEggsTime"));
const DeliverEggsDetails = lazy(() => import("./Component/Egg/DeliverEggsDetails"));
const Appointment = lazy(() => import("./Component/Appointment/Appointment"));
const ChangePassword = lazy(() => import("./Component/Profile/ChangePassword"));
const ForgetPassword = lazy(() => import("./Auth/ForgetPassword"));
const VerifyPasswordCode = lazy(() => import("./Auth/VerifyPasswordCode"));
const ResetPassword = lazy(() => import("./Auth/ResetPassword"));
//import { Elements } from '@stripe/react-stripe-js';
//import { loadStripe } from '@stripe/stripe-js';
// const SignVerify = lazy(() => import("./Auth/VerifyAccount"));
const NotFound = lazy(() => import("./Component/Errors/404NotFound"));
const VerifyAccount = lazy(() => import("./Auth/VerifyAccount"));
const VerfifyEmail = lazy(() => import("./Auth/VerifyEmail"));
const SendEmailForForgetPassword = lazy(() => import("./Auth/SendEmailForForgetPassword"));
const VerifyPasswordCodeByEmail = lazy(() => import("./Auth/VerifyPasswordCodeByEmail"));
function App() {
  return (
    <div className="App">
      <SnackbarProvider autoHideDuration={2000} maxSnack={1}>
        <BrowserRouter>
          <AuthProvider>
            <BalanceProvider>
              <AppContent />
            </BalanceProvider>
          </AuthProvider>
        </BrowserRouter>
      </SnackbarProvider>
    </div>
  );
}

function AppContent() {

  const pulseTypography = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  50% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(0);
  }
`;
  const location = useLocation();
  const isLoginPage = ["/profile", "/my_marketplace", "/FirstTrack", "/ChangePassword", "/DeliverEggsTime", "/DeliverEggAddress", "/AddBalance", "/MyEggs", "/Orders", "/OrderIncomingDetails", "/OrderHistoryDetails", "/SecondTrack", "/AddAddress", "/EditAddress", "/Balance", "/my_hens", "/orders", "/address", "/my_wishlist", "/EditStore", "/notification", "/languages", "/connect_list", "/appointment"]
    .some(path => location.pathname.startsWith(path)) || location.pathname.match(/^\/my_hens\/\d+$/);

  return (
    <>
      <Suspense fallback={
        <Box className="suspens">

          <img src={wait} alt="wait" style={{ width: "250px", height: "220px" }} />
          <Typography
            color="#00b207" marginRight="15px" fontWeight="800" letterSpacing="1.5px"
            sx={{ animation: `${pulseTypography} 2s ease-in-out infinite`, textAlign: "center", marginTop: "10px", fontSize: { xs: "22px", sm: "34px", md: "36px" } }}
          >
            Please Wait
          </Typography>
        </Box>
      }>
        <main className="content" >
          <div style={{ display: "flex", flexDirection: "row", }}>
            <div>
              <Cart />
            </div>
            <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
              <Header />
              <div style={{ display: isLoginPage ? "flex" : "", height: "auto" }}>
                <div className="side" style={{ width: "17%" }}>
                  {isLoginPage && <Sidebar />}
                </div>
                <div className="con" style={{ width: isLoginPage ? "83%" : "100%" }}>
                  <Routes>
                    <Route path="/home" element={<Home />} />
                    <Route path="/about_us" element={<About />} />
                    <Route path="/privacy_policy" element={<Privacy />} />
                    <Route path="/faqs" element={<Faqs />} />
                    <Route path="/terms_of_use" element={<Terms />} />
                    <Route path="/contact_us" element={<Contact />} />
                    <Route path="*" element={<NotFound />} />
                    <Route path="/" element={<Home />} />
                    <Route path="/farms" element={<Farm />} />
                    <Route path="/map" element={<GoogleMap />} />
                    <Route path="/mapall" element={<GoogleMapAll />} />
                    <Route path="/farms/:farmid" element={<FarmDetails />} />
                    <Route path="/chicken" element={<Chicken />} />
                    <Route path="/chicken/:chickenid" element={<ChickenDetails />} />
                    {/* <Route path="/login" element={<Login />} /> */}
                    <Route path="/RejisterChoose" element={<RejisterChoose />} />
                    <Route path="/StepOne" element={<StepOne />} />
                    <Route path="/StepOneMap" element={<StepOneMap />} />
                    <Route path="/StepTwo" element={<StepTwo />} />
                    <Route path="/StepThree" element={<StepThree />} />
                    <Route
                      path="/ForgetPassword"
                      element={
                        <PublicRoute>
                          <ForgetPassword />
                        </PublicRoute>
                      }
                    />
                    <Route
                      path="/VerifyPasswordCode"
                      element={
                        <PublicRoute>
                          <VerifyPasswordCode />
                        </PublicRoute>
                      }
                    />
                    <Route path="/VerifyAccount"
                      element={
                        <PublicRoute>
                          <VerifyAccount />
                        </PublicRoute>
                      }
                    />
                    <Route
                      path="/VerifyEmail"
                      element={
                        <PublicRoute>
                          <VerfifyEmail />
                        </PublicRoute>
                      }
                    />
                    <Route
                      path="/SendEmailForForgetPassword"
                      element={
                        <PublicRoute>
                          <SendEmailForForgetPassword />
                        </PublicRoute>
                      }
                    />
                    <Route
                      path="/VerifyPasswordCodeByEmail"
                      element={
                        <PublicRoute>
                          <VerifyPasswordCodeByEmail />
                        </PublicRoute>
                      }
                    />
                    <Route
                      path="/ResetPassword"
                      element={
                        <PasswordRoute>
                          <ResetPassword />
                        </PasswordRoute>
                      }
                    />

                    <Route
                      path="/login"
                      element={
                        <PublicRoute>
                          <Login />
                        </PublicRoute>
                      }
                    />
                    <Route
                      path="/signin"
                      element={
                        <PublicRoute>
                          <Signin />
                        </PublicRoute>
                      }
                    />

                    <Route path="/store" element={<Store />} />
                    {/* <Route path="/cart" element={<Cart />} /> */}
                    <Route path="/EggsOrderDetails" element={<EggsOrderDetails />} />
                    <Route path="/EggsOrderAddress" element={<EggsOrderAddress />} />
                    <Route path="/EggsOrderTime" element={<EgssOrderTime />} />
                    <Route path="/storeDetails/:storeid" element={<StoreDetails />} />
                    <Route path="/egg/:eggid" element={<EggDetails />} />
                    <Route path="/ChickenOrderDetails" element={<ChickenOrderDetails />} />

                    <Route
                      path="/profile"
                      element={
                        <ProtectedRoute>
                          <Profile />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/ChangePassword"
                      element={
                        <ProtectedRoute>
                          <ChangePassword />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/my_wishlist"
                      element={
                        <ProtectedRoute>
                          <Mywishlist />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/my_hens"
                      element={
                        <ProtectedRoute>
                          <MyHens />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/deliever_chicken_address/:chickenid"
                      element={
                        <ProtectedRoute>
                          <DelieverdChickenAddress />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/deliever_chicken_time"
                      element={
                        <ProtectedRoute>
                          <DelieverdChickenTime />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/deliver_chicken_details"
                      element={
                        <ProtectedRoute>
                          <DelieverdChickenDetails />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/my_hens/edit/:chickenid"
                      element={
                        <ProtectedRoute>
                          <EditHens />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/my_hens/:chickenid"
                      element={
                        <ProtectedRoute>
                          <HensDetails />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/address"
                      element={
                        <ProtectedRoute>
                          <Address />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/AddAddress"
                      element={
                        <ProtectedRoute>
                          <AddAddress />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/EditAddress/:useraddresid"
                      element={
                        <ProtectedRoute>
                          <EditAddress />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/RequestFarm"
                      element={
                        <ProtectedRoute>
                          <RequestFarm />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/FirstRequest"
                      element={
                        <ProtectedRoute>
                          <FirstRequest />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/Backyard"
                      element={
                        <ProtectedRoute>
                          <Backyard />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/RequestAddress/:packageid"
                      element={
                        <ProtectedRoute>
                          <RequestAddress />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/RequestTime"
                      element={
                        <ProtectedRoute>
                          <RequestTime />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/RequestOrder"
                      element={
                        <ProtectedRoute>
                          <RequestOrder />
                        </ProtectedRoute>
                      }
                    />

                    {/* <Route
                  path="/StepOne"
                  element={
                    <ProtectedRoute>
                      <StepOne />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/StepOneMap"
                  element={
                    <ProtectedRoute>
                      <StepOneMap />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/StepTwo"
                  element={
                    <ProtectedRoute>
                      <StepTwo />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/StepThree"
                  element={
                    <ProtectedRoute>
                      <StepThree />
                    </ProtectedRoute>
                  }
                /> */}
                    <Route
                      path="/Balance"
                      element={
                        <ProtectedRoute>
                          <Balance />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/AddBalance"
                      element={
                        <ProtectedRoute>
                          <AddBalance />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/FirstTrack"
                      element={
                        <ProtectedRoute>
                          <FirstTrack />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/SecondTrack"
                      element={
                        <ProtectedRoute>
                          <SecondTrack />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/Orders"
                      element={
                        <ProtectedRoute>
                          <Orders />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/OrderHistoryDetails/:orderid"
                      element={
                        <ProtectedRoute>
                          <OrderHistoryDetails />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/OrderIncomingDetails/:orderid"
                      element={
                        <ProtectedRoute>
                          <OrderIncomingDetails />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/MyEggs"
                      element={
                        <ProtectedRoute>
                          <MyEggs />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/EditStore/:storeid"
                      element={
                        <ProtectedRoute>
                          <EditStore />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/EggDetails"
                      element={
                        <ProtectedRoute>
                          <EggDetails />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/DeliverEggAddress"
                      element={
                        <ProtectedRoute>
                          <DeliverEggAddress />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/DeliverEggsTime"
                      element={
                        <ProtectedRoute>
                          <DeliverEggsTime />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/deliver_eggs_details"
                      element={
                        <ProtectedRoute>
                          <DeliverEggsDetails />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/appointment"
                      element={
                        <ProtectedRoute>
                          <Appointment />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/Balance"
                      element={
                        <ProtectedRoute>
                          <Balance />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/AddBalance"
                      element={
                        <ProtectedRoute>
                          <AddBalance />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/FirstTrack"
                      element={
                        <ProtectedRoute>
                          <FirstTrack />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/SecondTrack"
                      element={
                        <ProtectedRoute>
                          <SecondTrack />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/Orders"
                      element={
                        <ProtectedRoute>
                          <Orders />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/OrderHistoryDetails/:orderid"
                      element={
                        <ProtectedRoute>
                          <OrderHistoryDetails />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/OrderIncomingDetails/:orderid"
                      element={
                        <ProtectedRoute>
                          <OrderIncomingDetails />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/MyEggs"
                      element={
                        <ProtectedRoute>
                          <MyEggs />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/EditStore/:storeid"
                      element={
                        <ProtectedRoute>
                          <EditStore />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/EggDetails"
                      element={
                        <ProtectedRoute>
                          <EggDetails />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/DeliverEggAddress"
                      element={
                        <ProtectedRoute>
                          <DeliverEggAddress />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/DeliverEggsTime"
                      element={
                        <ProtectedRoute>
                          <DeliverEggsTime />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/deliver_eggs_details"
                      element={
                        <ProtectedRoute>
                          <DeliverEggsDetails />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/appointment"
                      element={
                        <ProtectedRoute>
                          <Appointment />
                        </ProtectedRoute>
                      }
                    />
                    {/* <Route
                      path="/verifyaccount"
                      element={
                        <ProtectedRoute>
                          <Verficationaccount />
                        </ProtectedRoute>
                      }
                    /> */}

                  </Routes>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </main>
      </Suspense>
    </>
  );
}

export default App;
