// const Host = "https://api.rentchicken.net/api/"
const Host = "https://staging-api.rentchicken.net/api"
const user_id = JSON.parse(localStorage.getItem("user"))?.user_id
const SmS = "https://identitytoolkit.googleapis.com/v1/accounts:sendVerificationCode?key=AIzaSyBEpa64tNrZQ08vQuRtgb5WuXnbgtkPTvc"
export const api_Routes = {
    auth: {
        login: `${Host}/auth`,
        add: `${Host}/auth`,
        change: `${Host}/auth/changePassword`,
        withSocial: (provider) => (`${Host}/auth/social/${provider}`),
        sendByUsername: `${Host}/verification/sendByUsername`,
        sendSms: `${SmS}`,
        VerifyAccount: `${Host}/auth/verify-otp`,
        SendEmail: `${Host}/auth/sendPasswordByEmail`,
        VerifyEmail: `${Host}/auth/VerifyByEmail`,
        PasswordForgetOTB: `${Host}/auth/authenticate-otp`,
        ChangeOTBPassword: `${Host}/auth/change-otp-password`,
        SendEmailForForgetPassword: `${Host}/auth/sendPasswordByEmail`,

    },
    language: {
        view: `${Host}/languages`,
    },
    chicken: {
        view: `${Host}/chickens`,
        viewHens: `${Host}/chickens/rent/user`,
        add: `${Host}/chickens`,
        rent: `${Host}/chickens/rent`,
        renew: `${Host}/chickens/renew`,
        deliver: `${Host}/chickens/deliver`,
        bulkDelete: (id) => (`${Host}/chickens/${id}`),
        getOne: (id) => (`${Host}/chickens/${id}`),
        update: (id) => (`${Host}/chickens/${id}`),

    },
    egg: {
        view: `${Host}/eggs`,
        add: `${Host}/eggs`,
        bulkDelete: (id) => (`${Host}/eggs/${id}`),
        getOne: (id) => (`${Host}/eggs/${id}`),
        update: (id) => (`${Host}/eggs/${id}`),
        sell: `${Host}/eggs/addToStore`,

    },
    breed: {
        view: `${Host}/breeds`,
        add: `${Host}/breeds`,
        bulkDelete: (id) => (`${Host}/breeds/${id}`),
        getOne: (id) => (`${Host}/breeds/${id}`),
        update: (id) => (`${Host}/breeds/${id}`),
    },
    farm: {
        view: `${Host}/farms`,
        request: `${Host}/farms/add`,
        add: `${Host}/farms`,
        bulkDelete: (id) => (`${Host}/farms/${id}`),
        getOne: (id) => (`${Host}/farms/${id}`),
        update: (id) => (`${Host}/farms/${id}`),
    },
    role: {
        view: `${Host}/roles`,
        add: `${Host}/roles`,
        bulkDelete: (id) => (`${Host}/roles/${id}`),
        getOne: (id) => (`${Host}/roles/${id}`),
        update: (id) => (`${Host}/roles/${id}`),
    },
    permission: {
        view: `${Host}/permissions`,
        add: `${Host}/permissions/assign`,
        bulkDelete: (id) => (`${Host}/permissions/${id}`),
        getOne: (id) => (`${Host}/permissions/${id}`),
        update: (id) => (`${Host}/permissions/${id}`),
    },
    stripe: {
        add: `${Host}/stripe`,
    },
    user: {
        view: `${Host}/users`,
        add: `${Host}/users`,
        bulkDelete: (id) => (`${Host}/users/${id}`),
        getOne: (id) => (`${Host}/users/${id}`),
        update: (id) => (`${Host}/users/${id}`),
    },
    country: {
        view: `${Host}/countries`,
        add: `${Host}/countries`,
        bulkDelete: (id) => (`${Host}/countries/${id}`),
        getOne: (id) => (`${Host}/countries/${id}`),
        update: (id) => (`${Host}/countries/${id}`),
    },
    useraddress: {
        view: `${Host}/userAddresses?user_id=${user_id}`,
        add: `${Host}/userAddresses`,
        bulkDelete: (id) => (`${Host}/userAddresses/${id}`),
        getOne: (id) => (`${Host}/userAddresses/${id}`),
        update: (id) => (`${Host}/userAddresses/${id}`),
    },
    reservation: {
        view: `${Host}/reservations`,
        add: `${Host}/reservations`,
        bulkDelete: (id) => (`${Host}/reservations/${id}`),
        getOne: (id) => (`${Host}/reservations/${id}`),
        update: (id) => (`${Host}/reservations/${id}`),
    },
    store: {
        view: `${Host}/stores`,
        add: `${Host}/stores`,
        bulkDelete: (id) => (`${Host}/stores/${id}`),
        getOne: (id) => (`${Host}/stores/${id}`),
        update: (id) => (`${Host}/stores/${id}`),
        getUser: `${Host}/stores/user`

    },
    cart: {
        view: `${Host}/cartItems`,
        viewchicken: `${Host}/cartItems/chickens`,
        add: `${Host}/cartItems`,
        addchicken: `${Host}/cartItems/chicken`,
        bulkDelete: (id) => (`${Host}/cartItems/${id}`),
        bulkDeletechicken: (id) => (`${Host}/cartItems/chicken/${id}`),
        getOne: (id) => (`${Host}/cartItems/${id}`),
        update: (id) => (`${Host}/cartItems/${id}`),
        updatechicken: (id) => (`${Host}/cartItems/chicken/${id}`),
    },
    location: {
        view: `${Host}/locations`,
        add: `${Host}/locations`,
        bulkDelete: (id) => (`${Host}/locations/${id}`),
        getOne: (id) => (`${Host}/locations/${id}`),
        update: (id) => (`${Host}/locations/${id}`),
    },
    shippingoption: {
        view: `${Host}/shippingOptions`,
        add: `${Host}/shippingOptions`,
        bulkDelete: (id) => (`${Host}/shippingOptions/${id}`),
        getOne: (id) => (`${Host}/shippingOptions/${id}`),
        update: (id) => (`${Host}/shippingOptions/${id}`),
    },
    order: {
        view: `${Host}/orders`,
        incoming: `${Host}/orders?vendor_id=${user_id}`,
        add: `${Host}/orders`,
        confirm_egg_order: `${Host}/orders/confirm`,
        confirm_chicken_order: `${Host}/orders/chicken/confirm`,
        getOne: (id) => (`${Host}/orders/${id}`),
        history: `${Host}/orders/byUser?type=1`,
        update: (id) => (`${Host}/orders/${id}`),

    },
    balance: {
        view: `${Host}/balances/byUser`,
        auth: `${Host}/auth/balance`,
    },
    wishlist: {
        add: (id) => (`${Host}/wishlists/${id}`),
        view: `${Host}/wishlists`,
        view2: `${Host}/wishlists/byUser`,
    },
    deliver: {
        add: `${Host}/deliver`,
        getOne: (id) => (`${Host}/deliver/${id}`),
    },
    verification: {
        send_phone: `${Host}/verification/sendByPhone`,
        verify_code: `${Host}/verification/confirmByPhone`,
        forget: `${Host}/auth/forgottenPassword`,
    },
    package: {
        view: `${Host}/packages`,
    },
    rating: {
        view: `${Host}/ratings`,
        getOne: (id) => (`${Host}/ratings/${id}`),
        update: (id) => (`${Host}/ratings/${id}`),
        add: `${Host}/ratings`,
    },
    contact: {
        send: `${Host}/contact-us/send`,
    },
    check: {
        // username: `${Host}/auth/checkUsername`,
        // email: `${Host}/auth/checkEmail`,
        // phonenumber: `${Host}/auth/checkPhone`,
        checkall: `${Host}/auth/checkAll`,
    },
}
