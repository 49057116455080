import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext'; // Adjust the import path as needed

const PublicRoute = ({ children }) => {
    const { auth } = useAuth();

    // If user is authenticated, redirect to home page or any other protected page
    if (auth) {
        return <Navigate to="/home" />;
    }

    // If not authenticated, render the children components (login page)
    return children;
};

export default PublicRoute;
