import React, { useState, useEffect } from "react";
import { api_Routes } from "../../api_Route";
import { Helper } from "../../Tools/Helper";
import { useNavigate } from 'react-router-dom';
import { Typography, CircularProgress, Button, Box, TextField, InputAdornment } from '@mui/material';
import { useSnackbar } from 'notistack';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { styled } from '@mui/material/styles';

import { keyframes } from '@emotion/react';
import eggs_empty from "../../Assets/chicken-cart.gif"
const CartPopUpChicken = ({ isopen, onContinueBuying }) => {

    const { enqueueSnackbar } = useSnackbar();


    const navigate = useNavigate();
    const [cart, setCart] = useState([]);
    const [loadingItems, setLoadingItems] = useState({});
    const [loadingforconfirm, setloadingforconfirm] = useState(false)
    const [loadingchangequantity, setloadingchangequantity] = useState(false)
    const [openDialog, setOpenDialog] = useState(false);
    const [open, setopen] = useState(isopen);
    const [isLoading, setIsLoading] = useState(true);
    const [isloadingfordelete, setIsloadingfordelete] = useState(false)

    const [recordIdToDelete, setRecordIdToDelete] = useState(null);
    // const pulse = keyframes`
    //     0% {
    //         transform: scale(1);
    //     }
    //     50% {
    //         transform: scale(1.1);
    //     }
    //     100% {
    //         transform: scale(1);
    //     }
    //     `;
    const pulseTypography = keyframes`
        0% {
        opacity: 0;
        transform: translateY(-20px);
        }
        100% {
        opacity: 1;
        transform: translateY(0);
        }
        `;

    useEffect(() => {

        get_cart();

    }, []);

    const get_cart = async () => {
        const { response, message } = await Helper.Get({
            url: api_Routes.cart.viewchicken,
            hasToken: true,
        });
        console.log(response)
        if (response) {
            setIsLoading(false);
            setCart(response.data.map(ele => ({
                id: ele.id,
                farm_id: ele.farm_id,
                breed_id: ele.breed_id,
                breed_name: ele.breed?.name || '',
                breed_photo: ele.breed?.photo || '',
                quantity: ele.quantity,
                subtotal: ele.subtotal,
                photo: ele.photo,
                farm_name: ele.farm_name,
            })));
        } else {
            setIsLoading(false);
            console.log(message);
        }
    };

    const handleQuantityChange = async (id, farm_id, breed_id, newQuantity) => {
        if (newQuantity < 1) return;

        // Set individual item and confirm order button loading state
        setLoadingItems(prev => ({ ...prev, [id]: true }));
        setloadingforconfirm(true);

        setCart(prevCart => prevCart.map(item => (
            item.id === id ? { ...item, quantity: newQuantity } : item
        )));

        const { response, message } = await Helper.Post({
            url: api_Routes.cart.updatechicken(id),
            data: {
                farm_id: farm_id,
                breed_id: breed_id,
                quantity: newQuantity,
                type: 1
            },
            hasToken: true
        });

        if (response) {
            get_cart();
        } else {
            let errorMessage = '';
            if (typeof message === "string") {
                errorMessage = message;
            } else if (typeof message === "object") {
                errorMessage = Object.values(message).flat().join(', ');
            }
            enqueueSnackbar(errorMessage, {
                variant: "error",
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });
            get_cart();
        }

        // Reset the loading state for the specific item and confirm button
        setLoadingItems(prev => ({ ...prev, [id]: false }));
        setloadingforconfirm(false);
    };


    const handleOpenDialog = (id) => {
        setRecordIdToDelete(id);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    // const CartItemBox = styled(Box)(({ theme }) => ({
    //     display: 'flex',
    //     alignItems: 'center',
    //     marginBottom: theme.spacing(4),
    //     border: '1px solid #e9e7e7',
    //     padding: theme.spacing(2),
    //     borderRadius: theme.spacing(2),
    //     flexDirection: 'column',
    //     justifyContent: 'space-between',
    //     [theme.breakpoints.up('sm')]: {
    //         flexDirection: 'row',
    //     },
    // }));


    // const CartImage = styled('img')(({ theme }) => ({
    //     marginRight: theme.spacing(2),
    //     width: '60px', // Larger image for better visuals
    //     height: '60px',
    //     objectFit: 'cover',
    // }));

    const handleDeleteConfirmed = async () => {
        setIsloadingfordelete(true);
        const { response, message } = await Helper.Delete({
            url: api_Routes.cart.bulkDeletechicken(recordIdToDelete),
            hasToken: true,
        })
        if (response) {
            setIsloadingfordelete(false)
            enqueueSnackbar(message, {
                variant: "success", anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            })
            get_cart()
        } else {
            enqueueSnackbar(message, {
                variant: "error", anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            })

            setIsloadingfordelete(false)
        }
        setOpenDialog(false);
    };

    const handleSubmit = async () => {
        setloadingforconfirm(true);

        const { response, message } = await Helper.Put({
            url: api_Routes.order.add,
            data: {
                type: 2
            },
            hasToken: true
        });

        if (response) {
            enqueueSnackbar(message, {
                variant: "success",
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });
            setloadingforconfirm(false);
            localStorage.setItem("order_id_chicken", response.data.id);
            setopen(false);
            navigate('/ChickenOrderDetails');
        } else {
            let errorMessage = '';
            if (typeof message === "string") {
                errorMessage = message;
            } else if (typeof message === "object") {
                errorMessage = Object.values(message).flat().join(', ');
            }
            enqueueSnackbar(errorMessage, {
                variant: "error",
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });
        }

        setloadingforconfirm(false);
    };

    // const handleAddToCart = async (id, farm_id, breed_id) => {

    //     setloadingchangequantity(true)

    //     const selectedChicken = cart.find(e => e.id === id);

    //     const { response, message } = await Helper.Post({
    //         url: api_Routes.cart.updatechicken(id),
    //         data: {
    //             farm_id: farm_id,
    //             breed_id: breed_id,
    //             quantity: selectedChicken.quantity,
    //             type: 1
    //         },
    //         hasToken: true
    //     });

    //     if (response) {
    //         enqueueSnackbar(message, {
    //             variant: "success", anchorOrigin: {
    //                 vertical: 'top',
    //                 horizontal: 'right'
    //             }
    //         })
    //         get_cart()
    //         setloadingchangequantity(false)
    //         //   navigate(`/chicken`)     
    //         // setIsLoading(false);
    //     } else {
    //         let errorMessage = '';
    //         if (typeof message === "string") {
    //             errorMessage = message;
    //         } else if (typeof message === "object") {
    //             errorMessage = Object.values(message).flat().join(', ');
    //         }
    //         enqueueSnackbar(errorMessage, {
    //             variant: "error",
    //             anchorOrigin: {
    //                 vertical: 'top',
    //                 horizontal: 'right'
    //             }
    //         });
    //         setloadingchangequantity(false)
    //         get_cart()

    //     }
    // };


    return (<>

        <Dialog open={openDialog} onClose={handleCloseDialog}>
            <DialogTitle sx={{ color: "#E30000" }}>Confirm Delete</DialogTitle>
            <DialogContent>
                <Typography>Are you sure you want to delete this item?</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog}>Cancel</Button>
                <Button sx={{ color: "#E30000" }} onClick={handleDeleteConfirmed}>     {isloadingfordelete ? <CircularProgress size={22} sx={{ color: "green" }} /> : "yes delete"}</Button>
            </DialogActions>
        </Dialog>


        <Box>
            {isLoading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh', }}>
                    <CircularProgress sx={{ color: "green" }} />
                </Box>
            ) : (
                <>
                    <Button
                        variant="contained"
                        sx={{ marginBottom: 2, backgroundColor: "#00b207", color: "white", '&:hover': { backgroundColor: "#00b207", color: "white" } }}
                        onClick={get_cart}
                    >
                        Refresh Cart
                    </Button>
                    {/* <CloseIcon sx={{ cursor: "pointer" }} onClick={() => { setopen(false) }} />
                <Typography textAlign={"center"} fontSize={"22px"} fontWeight={600} mb={2}>Cart</Typography>
                <Typography textAlign={"start"} fontSize={"18px"} fontWeight={600} sx={{ color: "#00b207", textDecoration: "underline" }} mt={2} mb={2}>Chicken Cart</Typography> */}
                    {isloadingfordelete ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh', }}>
                            <CircularProgress sx={{ color: "green" }} />
                        </Box>
                    ) : (
                        <>
                            {cart.map((item) => (
                                <Box
                                    key={item.id}
                                    display="flex"
                                    alignItems="center"
                                    mb={5}
                                    sx={{
                                        border: "1px solid #e9e7e7",
                                        padding: "20px",
                                        boxSizing: "border-box",
                                        borderRadius: "14px",
                                        flexDirection: { xs: 'column', sm: 'row' },
                                        justifyContent: "space-between",
                                        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                                        "&:hover": { boxShadow: "0 6px 16px rgba(0, 0, 0, 0.15)" },
                                    }}
                                >
                                    <Box display="flex" alignItems="center">
                                        <img
                                            src={item.photo}
                                            alt="cart photo"
                                            style={{ marginRight: 20, width: "40px", height: "55px", borderRadius: "8px" }}
                                        />
                                        <Box>
                                            <Typography sx={{ fontSize: "16px", fontWeight: "600", mb: 1 }}>{item.farm_name}:</Typography>
                                            <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>{item.breed_name}</Typography>
                                        </Box>
                                    </Box>

                                    <Box display="flex" alignItems="center" sx={{ marginTop: { xs: '10px', md: '0', sm: '0', lg: '0' } }}>
                                        <Typography sx={{ fontSize: "18px", fontWeight: "bold", color: "#00b207", marginRight: 2 }}>
                                            {item.subtotal} $
                                        </Typography>

                                        <Box display="flex" alignItems="center">
                                            <TextField
                                                variant="outlined"
                                                value={item.quantity}
                                                // onChange={(e) => handleQuantityChange(item.id, item.farm_id, item.breed_id, parseInt(e.target.value))}
                                                onChange={(e) => {
                                                    const value = e.target.value;

                                                    const quantity = value === '' ? 0 : parseInt(value, 10);

                                                    if (!isNaN(quantity)) {
                                                        handleQuantityChange(item.id, item.farm_id, item.breed_id);
                                                    }
                                                }}
                                                sx={{
                                                    width: 100,
                                                    "& .MuiInputBase-root": { borderRadius: "8px" },
                                                }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            {loadingItems[item.id] ? (
                                                                <CircularProgress size={20} />
                                                            ) : (
                                                                <RemoveIcon
                                                                    onClick={() => handleQuantityChange(item.id, item.farm_id, item.breed_id, item.quantity - 1)}
                                                                    sx={{ cursor: "pointer", fontSize: "20px", color: "#888", "&:hover": { color: "#000" } }}
                                                                />
                                                            )}
                                                        </InputAdornment>
                                                    ),
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            {loadingItems[item.id] ? (
                                                                <CircularProgress size={20} />
                                                            ) : (
                                                                <AddIcon
                                                                    onClick={() => handleQuantityChange(item.id, item.farm_id, item.breed_id, item.quantity + 1)}
                                                                    sx={{ cursor: "pointer", fontSize: "20px", color: "#888", "&:hover": { color: "#000" } }}
                                                                />
                                                            )}
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            <svg
                                                width="30"
                                                height="30"
                                                viewBox="0 0 43 42"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                style={{ marginLeft: 16, cursor: "pointer", transition: "transform 0.2s ease-in-out" }}
                                                onClick={() => handleOpenDialog(item.id)}
                                                onMouseOver={(e) => (e.currentTarget.style.transform = "scale(1.1)")}
                                                onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")}
                                            >
                                                <path
                                                    d="M34.625 42H8.375C4.02538 42 0.5 38.4746 0.5 34.125V7.875C0.5 3.52538 4.02538 0 8.375 0H34.625C38.9746 0 42.5 3.52538 42.5 7.875V34.125C42.5 38.4746 38.9746 42 34.625 42Z"
                                                    fill="#F73C3D"
                                                />
                                                <path
                                                    d="M29.7141 15.5034C26.6736 15.2021 23.6149 15.0469 20.5654 15.0469C18.7576 15.0469 16.9497 15.1382 15.1419 15.3208L13.2793 15.5034"
                                                    stroke="white"
                                                    strokeWidth="1.36957"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M18.3027 14.5789L18.5036 13.3828C18.6497 12.5154 18.7593 11.8672 20.3023 11.8672H22.6945C24.2375 11.8672 24.3562 12.552 24.4932 13.392L24.694 14.5789"
                                                    stroke="white"
                                                    strokeWidth="1.36957"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M27.7528 18.3867L27.1594 27.5811C27.0589 29.0145 26.9767 30.1285 24.4294 30.1285H18.5676C16.0202 30.1285 15.9381 29.0145 15.8376 27.5811L15.2441 18.3867"
                                                    stroke="white"
                                                    strokeWidth="1.36957"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path d="M19.9727 25.1094H23.0131" stroke="white" strokeWidth="1.36957" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M19.2148 21.457H23.7801" stroke="white" strokeWidth="1.36957" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </Box>
                                    </Box>
                                </Box>
                            ))}
                        </>
                    )}


                    {cart.length === 0 ?


                        <Box display="flex" justifyContent="center" alignItems={"center"} sx={{ flexDirection: "column", marginTop: "70px" }}>
                            <img src={eggs_empty} alt="empty" style={{ width: "50%", height: "50%" }} />

                            <Typography color="#00b207" marginRight="15px" fontWeight="700" fontSize="25px" letterSpacing="1.1px" sx={{ animation: `${pulseTypography} 1s ease forwards`, }}>Lets Rent Some Chicken </Typography>
                            <Button variant="contained" sx={{ width: "150px", backgroundColor: "#00b207", color: "white", '&:hover': { backgroundColor: "#00b207", color: "white" }, marginTop: "15px" }} onClick={() => { navigate('/farms') }} >Rent Chicken</Button>
                        </Box>
                        :
                        <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                            <Button
                                variant="contained"
                                sx={{ backgroundColor: "#00b207", color: "white", textTransform: "none", fontSize: "15px", width: "40%", marginRight: "8px", '&:hover': { backgroundColor: "#00b207", color: "white" } }}
                                onClick={handleSubmit}
                                disabled={loadingforconfirm} // Disable button when loading
                            >
                                {loadingforconfirm ? <CircularProgress size={25} sx={{ color: "white" }} /> : "Confirm Order"}
                            </Button>
                        </Box>
                    }
                </>
            )}
        </Box>

    </>)

}
export default CartPopUpChicken;