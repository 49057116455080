import React, { useState, useEffect } from 'react';
import { Box, Tabs, Tab, Typography, Container } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import EggCart from './CartPopUpEgg'; // Eggs Cart Component
import ChickenCart from './CartPopUpChicken'; // ChickenCart Component

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Cartpopup = ({ isopen, initialTab = 0, onClose }) => {
    const [open, setOpen] = useState(isopen);
    const [value, setValue] = useState(initialTab);

    useEffect(() => {
        setOpen(isopen);
        console.log('isopen prop changed:', isopen);
    }, [isopen]);

    useEffect(() => {
        setValue(initialTab);
    }, [initialTab]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleClose = () => {
        console.log('Close button clicked');
        setOpen(false);
        if (onClose) {
            console.log('Executing onClose function');
            onClose(); // Notify parent to close the cart
        } else {
            console.log('onClose is not defined');
        }
    };

    return (
        open && (
            <Container>
                <Box
                    position="fixed"
                    sx={{
                        right: 0,
                        top: 0,
                        width: '100%',
                        maxWidth: '460px',
                        height: '100vh',
                        backgroundColor: 'white',
                        padding: { xs: "4px", sm: '20px' },
                        overflowY: 'auto',
                        boxShadow: 3,
                        zIndex: 10,
                    }}
                >
                    <CloseIcon
                        sx={{ cursor: 'pointer', position: 'absolute', top: 10, right: 10 }}
                        onClick={handleClose}
                    />
                    <Typography textAlign="center" fontSize="22px" fontWeight={600} mb={2}>
                        Cart
                    </Typography>

                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="cart tabs"
                            textColor="secondary"
                            indicatorColor="secondary"
                            sx={{
                                '& .MuiTabs-indicator': {
                                    backgroundColor: '#00B207', // Color for the indicator (underline) of the active tab
                                },
                                '& .MuiTab-root.Mui-selected': {
                                    color: '#00B207', // Color for the text of the active tab
                                },
                            }}>
                            <Tab label="Eggs Cart" {...a11yProps(0)} />
                            <Tab label="Chicken Cart" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        {open && <EggCart isopen={true} onContinueBuying={handleClose} />}
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        {open && <ChickenCart isopen={true} onContinueBuying={handleClose} />}
                    </CustomTabPanel>
                </Box>
            </Container>
        )
    );
};

Cartpopup.propTypes = {
    isopen: PropTypes.bool.isRequired,
    initialTab: PropTypes.number,
    onClose: PropTypes.func, // Add prop type for onClose
};

export default Cartpopup;
