import React, { createContext, useState, useEffect, useContext } from 'react';
import { api_Routes } from "../../api_Route";
import { Helper } from "../../Tools/Helper";

const BalanceContext = createContext();

export const BalanceProvider = ({ children }) => {
    const [balance, setBalance] = useState(null);
    const [error, setError] = useState(null);
    const user = JSON.parse(localStorage.getItem("user"));

    const fetchBalance = async () => {
        const { response, message } = await Helper.Get({
            url: api_Routes.balance.auth,
            hasToken: true,
            data: {
                results: 100,
            }
        });

        if (response) {
            const newBalance = response.data;
            setBalance(newBalance);
            setError(null);

            const user = JSON.parse(localStorage.getItem("user"));

            if (user) {
                user.balance = newBalance;

                localStorage.setItem("user", JSON.stringify(user));
            
            }
        } else {
            console.log(message);
        }
    };

    useEffect(() => {
        if (user && user.token) {
            fetchBalance();
        } else {
            setBalance('No user token found'); // Handle the case where there's no user or token
        }
    }, []);

    return (
        <BalanceContext.Provider value={{ balance, error }}>
            {children}
        </BalanceContext.Provider>
    );
};

export const useBalance = () => {
    return useContext(BalanceContext);
};
