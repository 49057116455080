import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';


const PasswordRoute = ({ children }) => {
    const location = useLocation();
    const previousPath = location.state?.from;


    const isValidSource = previousPath === '/VerifyPasswordCode' || previousPath === '/VerifyPasswordCodeByEmail';

    return isValidSource ? children : <Navigate to="/ForgetPassword" replace />;
};

export default PasswordRoute;